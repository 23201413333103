/* eslint-disable no-restricted-syntax */
import { setupServiceInfo, getModuleFederatedName } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import serviceNames, { TranslationsService } from '~setup/TranslationsService';
import '~setup/ServiceBusController';
import '~setup/diContainer';

setupServiceInfo(__serviceInfo__);
const { name, version } = __serviceInfo__;

TranslationsService.setViews(name, getModuleFederatedName(name), version, []);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.translationsService
});
