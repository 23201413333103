export const ApptBooking = {
    addService: 'add_service',
    addToCalendar: 'add_to_calendar',
    oabLegalDisclaimer: 'oab_legal_disclaimer',
    afternoonLabel: 'afternoon',
    anyArtist: 'any_artist',
    allServicesSelectedSelectServiceCopy: 'max_service_quantity_selected',
    bookingDetailsLabel: 'booking_details',
    bookingSummary: 'booking_summary',
    bookAVirtualAppointment: 'book_a_virtual_appointment',
    bookNewAppointmentLabel: 'book_new_appointment',
    bookVirtualAppointment: 'virtual_appointment_cta',
    cancelAndStartOverLabel: 'cancel_and_start_over',
    cancellationConfirmationCopy: 'cancellation_confirmation_copy',
    cancellationLabel: 'cancellation',
    confirmationCopy: 'confirmation_copy',
    confirmationLabel: 'confirmation',
    confirmationStepZoomLinkLabel: 'virtual_appointment_confirmation_zoom_link_description',
    exclusiveServiceNotAvailable: 'exclusive_service_not_available',
    editReviewStepLabel: 'edit_review_step',
    eveningLabel: 'evening',
    goToVirtualAppointmentBooking: 'go_to_virtual_appointment_booking',
    inStoreConsultationCopy: 'physical_appointment_description',
    inStoreConsultationLabel: 'select_store',
    invalidJumpLinkText: 'invalid_jump_link',
    justForYou: 'just_for_you',
    locationCopy: 'location_copy',
    locationLabel: 'location',
    maxAppointmentServicesCopy: 'max_appointment_services_copy',
    maxAppointmentServicesLabel: 'max_appointment_services',
    morningLabel: 'morning',
    mustBeANumberLabel: 'must_be_a_number',
    nextMonthLabel: 'next_month',
    noServicesSelectedSelectServiceCopy: 'select_at_least_service_quantity',
    notesLabel: 'notes',
    noTimeslotsAvailableCopy: 'no_timeslots_available_message',
    noTimeslotsAvailableLabel: 'no_timeslots_available',
    oabEmailAddressInvalidMessage: 'oab_email_address_invalid',
    oabEmailLabel: 'oab_email',
    oabFirstNameLabel: 'oab_first_name',
    oabFreeLabel: 'oab_free',
    oabLastNameLabel: 'oab_last_name',
    oabMinLengthInvalidMessage: 'oab_min_length_message',
    oabNextLabel: 'oab_next',
    oabRequiredFieldMessage: 'oab_required_field',
    oabSubmitLabel: 'oab_submit',
    oabTermsAndConditionsLabel: 'oab_terms_and_conditions',
    enableGeolocation: 'enable_geolocation',
    oabNoStoresFound: 'no_stores_found',
    phoneLengthLabel: 'phone_length',
    phoneNumberLabel: 'phone_number',
    phoneValidation: 'phone_validation',
    previousMonthLabel: 'previous_month',
    previousStepLabel: 'previous_step',
    progressLabel: 'progress',
    removeService: 'remove_service',
    reviewBookingCopy: 'review_booking_copy',
    reviewBookingLabel: 'review_booking_label',
    scheduleCopy: 'schedule_copy',
    scheduleLabel: 'schedule',
    selectArtist: 'select_artist',
    selectDateCopy: 'select_date_and_time_copy',
    selectDateLabel: 'select_date_and_time',
    selectLocation: 'select_location',
    selectServiceCopy: 'select_service_copy',
    selectServiceHeading: 'select_service',
    selectState: 'select_state',
    selectStoreAllServices: 'book_appointment_main_title_both',
    selectStoreCopy: 'select_store_copy',
    selectStoreInStoreOnly: 'book_appointment_main_title_physical',
    selectStoreInstructions: 'select_store_instructions',
    selectStoreLabel: 'select_store',
    selectStoreVirtualOnly: 'book_appointment_main_title_virtual',
    serviceCopy: 'service_copy',
    serviceLabel: 'service',
    serviceReviewNumberPlural: 'service_review_number_plural',
    serviceReviewNumberSingular: 'service_review_number_singular',
    servicesLabel: 'services',
    serviceWriteReview: 'service_write_review',
    smsOptInLabel: 'sms_opt_in',
    someServicesSelectedSelectServiceCopy: 'select_up_to_max_service_quantity',
    virtualAppointmentLocationLabel: 'virtual_appointment_location_description',
    virtualConfirmationCopy: 'virtual_appointment_confirmation_description',
    virtualConsultationCopy: 'virtual_appointment_description',
    virtualConsultationLabel: 'virtual_appointment_title',
    whatLabel: 'what',
    whenLabel: 'when',
    whereLabel: 'where',
    createAccountHeading: 'save_your_appointments',
    createAccountSubHeading: 'cancel_or_rebook_your_appointment_online',
    createAccountButtonLabel: 'create_an_account',
    createAccountButtonUrl: 'account_signin_tmpl_link',
    helloLabel: 'hello',
    signInAccountHeading: 'manage_your_appointments',
    signInAccountSubHeading: 'sign_in_cancel_or_rebook_your_appointment_online',
    signInAccountButtonUrl: 'account_appointments_index_tmpl_link',
    viewAppointmentsHeading: 'view_your_appointments',
    viewAppointmentsSubHeading: 'save_time_and_never_forget_date',
    viewAppointmentsButtonLabel: 'view_appointments',
    viewAppointmentsButtonUrl: 'my_appointments_link',
    callStoreTout: 'call_the_store'
};
