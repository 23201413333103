export const CartErrors = {
    cartCostLimit: 'cart_cost_limit',
    cartItemQuantityLimit: 'cart_item_quantity_limit',
    cartItemsCountLimit: 'cart_items_count_limit',
    cartEmpty: 'cart_empty',
    emptyCartSignInMessage: 'cart_empty_signin',
    emptyCartSignInAction: 'cart_empty_sign_action',
    itemSoldOut: 'item_sold_out',
    itemOutOfStock: 'item_out_of_stock',
    itemCommingSoon: 'item_comming_soon',
    itemQuantityAdjusted: 'item_quantity_adjusted',
    saveForLaterTemporarilyOutOfStockLabel: 'save_for_later_tos',
    saveForLaterSoldOutNotification: 'save_for_later_sold_out',
    cartEmptyTitleLabel: 'cart_empty_title',
    cartLimitReached: 'cart_limit_reached',
    disqualifyWpCriteria: 'disqualify_wp_criteria',
    thresholdWpCriteria: 'threshold_wp_criteria',
    nonEligibleItemsPotentiallyAffectedOffer: 'non_eligible_items_potentially_affected_offer',
    transactionLimitReached: 'trans_limit',
    offerReplacedSkusRestricted: 'offer_replaced_skus_restricted',
    replenishmentDisclaimerDeclined: 'replenishment_disclaimer_declined',
    replenishmentNotApplied: 'replenishment_not_applied'
};
