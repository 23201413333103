export const Checkout = {
    // General
    checkOrderStatus: 'check_order_status',
    composedHelpfulLinks: 'composed_helpful_links',
    paymentInstructions: 'payment_instructions',
    billingPageTitle: 'billing_page_title',
    billingPageSubtitle: 'billing_page_subtitle',
    deliveryPageTitle: 'delivery_page_title',
    deliveryOptionsTitle: 'delivery_options_title',
    deliveryMethodStandard: 'delivery_method_standard',
    homeDeliveryTitle: 'home_delivery_title',
    homeDeliveryOptionsAvailable: 'home_delivery_options_available',
    clickCollectTitle: 'click_collect_title',
    deliveryInstructionsLabel: 'delivery_intructions',
    continueCheckoutLabel: 'continue_checkout',
    chooseBillingFromBookLablel: 'billing_choose_from_book',
    newBillingLabel: 'new_billing_address',
    noticeOfFinanicalIncentiveLabel: 'california_resident_notice_of_financial_incentive',
    paymentPageTitle: 'payment_page_title',
    paymentMethodSelectionTitle: 'payment_method_selection_title',
    placeOrderLabel: 'place_order',
    creditCardSelectorLabel: 'credit_card_selector',
    newCreditCardLabel: 'add_credit_card',
    cardSaveLabel: 'save_credit_card',
    orderConfirmationPageTitle: 'order_confirmation_page_title',
    orderConfirmationTitle: 'order_confirmation_title',
    orderConfirmationMessage: 'order_confirmation_message',
    orderNowAndShipLater: 'order_now_and_ship_later',
    guestUserOrderConfimationMessage: 'guest_user_order_confirmation_message',
    otherProductsTitle: 'other_products_component_title',
    signInOrSignUpTitle: 'sign_in_or_sign_up_title',
    signInOrSignUpText: 'sign_in_or_sign_up_text',
    signInOrSignUpLabel: 'sign_in_or_sign_up',
    checkoutLabel: 'checkout',
    billingToLabel: 'billing_to',
    billingAddress: 'billing_address',
    billingLimitedCountryLabel: 'billing_limited_country_label',
    orderDateLabel: 'order_date',
    orderNumberLabel: 'order_number',
    orderHistoryTitle: 'order_history_title',
    seeAllOrders: 'see_all_orders',
    noOrdersMessage: 'no_orders_message',
    discoverNewProducts: 'discover_new_products',
    allOrders: 'all_orders',
    onTheWay: 'on_the_way',
    trackPackageLabel: 'track_package',
    orderDetailsTitle: 'order_details_title',
    billingInformation: 'billing_information',
    carrierLabel: 'carrier',
    trackingNumberLabel: 'tracking_number',
    paymentMethodLabel: 'payment_method',
    temporaryModuleTitle: 'temporary_module_title',
    orderPlacedLabel: 'order_placed',
    orderStatusLabel: 'order_status',
    orderInformationLabel: 'order_information',
    viewOrderDetailsText: 'view_order_details',
    emailSignup: 'email_signup',
    phoneUsedForImportantQuestions: 'phone_used_for_important_questions',
    complimentaryGift: 'complimentary_gift',
    guestCheckoutLabel: 'guest_checkout',
    guestCheckoutConsent: 'guest_checkout_consent',

    // Giftwrap
    giftWrap: 'gift_wrap',
    giftWrapOrderHidePrices: 'gift_wrap_order_hide_prices',
    wrapAllItemsInSingleBox: 'wrap_all_items_in_single_box',
    giftMessage: 'gift_message',
    giftMessagePlaceholderText: 'gift_message_placeholder_text',
    giftWrapOptions: 'gift_wrap_options',
    giftWrapAndPersonalMessage: 'gift_wrap_and_personal_message',
    giftWrapAddedSuccessfully: 'gift_wrap_added_successfully',
    giftMessageAddedSuccessFully: 'gift_message_added_successfully',
    giftMessageNotAvailable: 'gift_message_not_available',
    giftMessageProfanityErrorCheck: 'gift_message_profanity_error_check',
    //TODO: get image path from shopkeeper
    giftWrapImage: 'gift_wrap_image',

    // Progress bar
    progressDeliveryStep: 'checkout_progress_delivery',
    progressPaymentStep: 'checkout_progress_payment',
    progressConfirmationStep: 'checkout_progress_confirmation',
    progressShippingStep: 'checkout_progress_shipping',
    progressBillingStep: 'checkout_progress_billing',

    // Shipping
    shippingPageTitle: 'shipping_page_title',
    defaultShippingAddress: 'default_shipping_address',
    yourDefaultDeliveryAddress: 'your_default_delivery_address',
    shippingAddress: 'shipping_address',
    shippingToLabel: 'shipping_to',
    shippingMethod: 'shipping_method',
    sameBillingCheckboxLabel: 'same_billing_address_checkbox',
    billingAddressTitle: 'billing_address_title',
    homeDeliveryOptionsTitle: 'home_delivery_options',
    homeDeliveryOptionsAvailableTitle: 'home_delivery_options_available',
    fillOutPostalCode: 'fill_out_postal_code',
    chooseDeliveryOptions: 'choose_delivery_options',
    preferredDayOfDelivery: 'preferred_day_of_delivery',
    preOrderMessage: 'pre_order_message',
    shippingSetToStandard: 'shipping_set_to_standard',
    shippingErrorMessage: 'shipping_error_message',
    shippingLimitedCountryLabel: 'shipping_limited_country_label',
    shippingLegalCompliance: 'shipping_legal_compliance',
    estimatedDelivery: 'estimated_delivery',
    differentLocationError: 'different_location_error',

    // Order and order history
    orderTypeOnlineLabel: 'order_type_online',
    cancelOrderLabel: 'cancel_order_label',

    // Item count
    orderItemCountSingular: 'order_item_count_singular',
    orderItemCountPlural: 'order_item_count_plural',

    // Order status
    orderStatusCancelled: 'order_status_cancelled',
    orderStatusDispatching: 'order_status_dispatching',
    orderStatusDelivered: 'order_status_delivered',
    orderStatusProcessing: 'order_status_processing',

    // Adyen specific helpful links
    helpfulLink1: 'adyen_helpful_link_1',
    helpfulLink2: 'adyen_helpful_link_2',
    helpfulLink3: 'adyen_helpful_link_3',
    helpfulLink4: 'adyen_helpful_link_4',
    helpfulLink5: 'adyen_helpful_link_5',
    helpfulLink6: 'adyen_helpful_link_6',
    helpfulLink7: 'adyen_helpful_link_7',

    // Card
    cardDebitCardOption: 'credit_debit_card_option',
    makeDefaultCard: 'make_default_card',
    defaultCard: 'default_card',
    deleteCard: 'delete_card',
    cardExpiration: 'card_expiration',
    cardExpired: 'card_expired',
    noCardTokensMessage: 'no_card_tokens_message',
    cvvLabel: 'cvv',
    cardNumberLabel: 'card_number',
    cardExpiryLabel: 'card_expiry',
    cardNumberPlaceholder: 'card_number_placeholder',
    cardExpiryPlaceholder: 'card_expiry_placeholder',
    cvvPlaceholder: 'card_cvv_placeholder',
    cardHolderNameLabel: 'card_holder_name',
    cardHolderNamePlaceholder: 'card_holder_name_placeholder',
    visaCardLabel: 'visa_card_payment_option',
    mastercardCardLabel: 'mastercard_payment_option',
    maestroCardLabel: 'maestro_card_payment_option',
    visaElectronCardLabel: 'visa_electron_card_payment_option',
    amexCardLabel: 'amex_card_payment_option',

    // Cash on delivery
    codPaymentOption: 'cod_payment_option',
    codPaymentNotAvailable: 'cod_payment_not_available',

    // Alipay
    alipayPaymentOption: 'alipay_payment_option',
    continueWithAlipay: 'continue_with_alipay',

    // Afterpay
    afterpayPaymentMethod: 'afterpay_payment_method',
    afterpayAddressWarning: 'afterpay_address_warning',
    afterpayNotEligibleMessage: 'afterpay_not_eligible_message',
    afterpayInstallmentPayInMessage: 'afterpay_installment_pay_in_message',
    afterpayPaymentAgreementLink: 'afterpay_payment_agreement_link',
    continueWithAfterpay: 'continue_with_afterpay',

    // Altapay
    mobilePayLabel: 'mobilepay_payment_option',

    // Pay by link
    payByLink: 'pay_by_link',
    payByLinkPaymentMode: 'paybylink_payment_mode',

    // Wechat payment option
    wechatPaymentOption: 'wechat_payment_option',

    // Klarna payment option
    payLaterWithKlarna: 'pay_later_with_klarna',
    payOverTimeWithKlarna: 'pay_over_time_with_klarna',
    klarnaNotAvailableForPurchase: 'klarna_not_available_for_purchase',

    // Mockpay payment option
    mockpayPaymentOption: 'mockpay_payment_option',

    // Venmo payment option
    venmoPaymentOption: 'venmo_payment_option',

    // Ingenico
    sofortLabel: 'sofort_payment_method',
    continueWithSofort: 'continue_with_sofort',
    idealLabel: 'ideal_payment_method',
    continueWithIdeal: 'continue_with_ideal',

    // Delete payment
    deletePayment: 'delete_payment',
    deletePaymentDefault: 'delete_payment_default',
    deletePaymentConfirm: 'delete_payment_confirm',
    deletePaymentCancel: 'delete_payment_cancel',

    // Default payment
    choosePayment: 'choose_another_payment',
    choosePaymentUseAsDefault: 'choose_another_payment_use_as_default',
    choosePaymentConfirm: 'choose_another_payment_confirm',
    choosePaymentCancel: 'choose_another_payment_cancel',
    continueWithPayPal: 'continue_with_paypal',
    payPalLabel: 'paypal_payment_method',
    securedPaymentInfo: 'secured_payment_info',

    // Product reorder
    reorderButtonLabel: 'reorder',
    reorderFreePromoButtonLabel: 'reorder_free_promo',
    reorderProductNoLongerAvailable: 'reorder_product_no_longer_available',

    // Click and collect
    searchPickupPointButtonText: 'search_pickup_point',
    findPickupPointText: 'find_pickup_point',
    addressSearchPlaceholder: 'address_search_placeholder',
    nearestPickUpPointText: 'nearest_pickup_point',
    storeClosedText: 'store_closed',
    openingHoursText: 'opening_hours',
    selectLocationButtonText: 'select_location',
    daysOfTheWeek: 'days_of_the_week',
    noPickUpPointsTitle: 'no_pickup_points_title',
    noPickUpPointsCopy: 'no_pickup_points',
    pleaseUseADifferentAddress: 'please_use_a_different_address',
    orChooseHomeDelivery: 'or_choose_home_delivery',
    searchPickupPointsAgainCopy: 'search_pickup_points_again',
    loadMoreLocationsText: 'load_more_locations',
    markerIconURL: 'marker_logo_url',
    changeLocationButtonText: 'change_location',
    selectedStoreText: 'selected_store_text',
    pickupPointInformationCopy: 'pickup_point_information',
    pickupInformation: 'pickup_information',
    clickAndCollectPopupInfoTitle: 'popup_info_collect_from_local_shop',
    clickAndCollectPopupInfoSubTitle: 'popup_info_subtitle',
    clickAndCollectPopupInfoGetNotifiedTitle: 'get_notified',
    clickAndCollectPopupInfoGetNotifiedCopy: 'popup_info_get_notified_copy',
    clickAndCollectPopupInfoProvideDocumentsTitle: 'provide_documents',
    clickAndCollectPopupInfoProvideDocumentsCopy: 'popup_info_provide_documents_copy',
    clickAndCollectPopupInfoSignPackageTitle: 'sign_package',
    clickAndCollectPopupInfoSignPackageCopy: 'popup_info_sign_package_copy',
    clickAndCollectPopupInfoOrderDispatchCopy: 'popup_info_order_dispatch',
    clickAndCollectPopupInfoSmallPrint: 'popup_info_small_print',
    cartItemRewardLabel: 'cart_item_reward_label',
    cartItemRewardRedeemedLabel: 'cart_item_reward_redeemed_label',
    editBagFistCopy: 'edit_bag_first_copy',
    editBagDescription: 'edit_bag_description',
    engravingNotAvailable: 'engraving_not_available',
    unavailableItems: 'unavailable_items',
    selectOption: 'select_option',
    selectAllItems: 'select_all_items',
    selectOtherStore: 'select_other_store',
    removeItemsAndContinue: 'remove_items_and_continue',
    removeItemsAndContinueWithGift: 'remove_items_and_continue_with_gift',
    allItemsForPickup: 'all_items_available_for_pickup',
    noItemsForPickup: 'no_items_available_for_pickup',
    someItemsForPickup: 'some_items_available_for_pickup',
    selectStore: 'select_store',
    selectDifferentStore: 'select_different_store',
    seeMoreLocations: 'see_more_locations',
    purchaserDetails: 'purchaser_details',
    replenishmentRemovedMessage: 'auto_replenishment_not_available_with_same_day',
    whoPickup: 'who_pickup',
    samplesInfoMessage: 'samples_info_message',
    sameDayDeliveryDisclaimer: 'same_day_delivery_disclaimer',
    sameDayDeliveryLabel: 'same_day_delivery',
    sameDayDeliveryGetItBy: 'same_day_delivery_get_it_by',
    sameDayDeliveryInfoText: 'same_day_delivery_more_info',
    SameDayNotAvailableText: 'same_day_not_available',
    sameDayDeliveryVariableFeeMessage: 'same_day_delivery_variable_fee_message',
    sameDayDeliveryPromotionDeliveryPrice: 'same_day_delivery_promotion_delivery_price',
    someOneMustBePresent: 'someone_must_be_present_for_delivery',
    chooseSameDayDeliveryLabel: 'choose_same_day_delivery_label',
    chooseSameDayDeliveryWindow: 'choose_same_day_window',
    checkAvailabilityAlert: 'check_availability_before_continue',
    toBeDeterminedLabel: 'to_be_determined',
    bopisLabel: 'bopis_shipping',
    courierLabel: 'courier_shipping',
    bopisInfoList: 'bopis_info_list',
    bopisInfoTooltipDescription: 'bopis_info_tooltip_description',
    bopisInfoTooltipMessage: 'bopis_info_tooltip_message',
    bopisShippingAllowSubstitutions: 'bopis_shipping_allow_substitutions',
    selectedStoreInfoTooltipDescription: 'selected_store_info_tooltip_description',
    selectedStoreInfoTooltipMessage: 'selected_store_info_tooltip_message',
    purchaserDetailsPayment: 'purchaser_details_payment',
    pickedUpInStoreBy: 'picked_up_in_store_by',
    storeForPickUp: 'store_for_pickup',
    availableForPickupToday: 'available_for_pickup_today',
    availableForPickupNextBusinessDay: 'available_for_pickup_next_business_day',
    checkAvailability: 'check_availability',
    noStoresFound: 'no_stores_found',
    localPickupPointsOnly: 'local_pickup_points_only',
    brandStoresOnly: 'brand_stores_only',

    // Salon commission
    salonCommissionCheckbox: 'yes_i_was_referred_by_a_salon',
    salonCommissionDescription: 'tell_us_your_salon_so_we_can_thank_them',
    salonCommissionFirstCopy: 'where_you_referred_by_a_salon',
    salonCommissionMySalon: 'my_salon',
    salonCommissionNearbySalons: 'nearby_salons',
    salonCommissionNoSalonNearYourLocation: 'no_salon_near_your_location',
    salonCommissionPrivacyPolicyLink: 'salon_commission_privacy_policy_link',
    salonCommissionPrivacyPolicyMessage: 'salon_commission_privacy_policy',
    salonCommissionSearchButton: 'search_salons',
    salonCommissionSearchInputPlaceholder: 'zip_code',
    salonCommissionSearchResults: 'search_results_for_zipcode',
    salonCommissionStylistCredit: 'give_your_stylist_some_credit',
    salonCommissionStylistName: 'stylist_name',
    salonCommissionViewMoreSalons: 'view_more_salons',

    // UPS address validation
    upsAddressValidationTitleLabel: 'ups_validation_title',
    upsAddressValidationSubtitleLabel: 'ups_validation_subtitle',
    upsAddressValidationRecommendedAddressLabel: 'ups_validation_recommended_address',
    upsAddressValidationEnteredAddressLabel: 'ups_validation_entered_address',
    upsAddressValidationUseAddressLabel: 'ups_validation_use_address',
    upsAddressValidationKeepAddressLabel: 'ups_validation_keep_address',

    // Gift card redemption
    giftCardNumberLabel: 'gift_card_number',
    giftCardPinLabel: 'gift_card_pin',
    giftCardApplyAnotherLabel: 'gift_card_apply_another',
    giftCardMoreInfoLabel: 'gift_card_more_info',
    giftCardAppliedMessage: 'gift_card_applied',
    giftCardRemovedMessage: 'gift_card_removed',
    giftCardBalanceLabel: 'gift_card_balance',
    giftCardBalanceMessage: 'the_balance_of_gift_card_is',
    giftCardCheckBalanceLabel: 'check_balance',
    giftCardCartChangedErrorMesage: 'gift_card_cart_changed',
    giftCardExpirationDate: 'gift_card_expiration_date',
    giftCardNotAvailableWithSelectedPayment: 'gift_card_not_available_with_selected_payment',
    giftCardNotAvailableWithARProducts: 'gift_card_not_available_with_ar_products',
    giftCardTerms: 'gift_card_terms',
    giftWrapRemovedLabel: 'gift_wrap_removed_in_store_pickup',

    // Our guarantee section
    ourGuaranteeFirstCopy: 'our_guarantee_first_copy',
    ourGuaranteeDescription: 'our_guarantee_description',

    // Iyzico payment
    preInformationConditionsLabel: 'pre_information_conditions',
    distanceSellingAgreementLabel: 'distance_selling_agreement',
    distanceSellingAgreementLink: 'distance_selling_agreement_link',
    continueWithIzyco: 'continue_with_izyco',
    preInformationConditionsAndDistanceSellingAgreementAlert:
        'pre_information_conditions_and_distance_selling_agreement_alert',

    // Packstation
    packstation: 'packstation',
    packstationPostNumber: 'packstation_post_number',
    packstationNumber: 'packstation_number',
    packstationLocation: 'packstation_location',
    haveYourOrderToAPackstation: 'have_your_order_to_a_packstation',
    packstationLimitedCountryLabel: 'packstation_limited_country_label',

    // Company Billing
    companyDetails: 'company_details',
    billTheCompany: 'bill_the_company',
    taxOfficeName: 'tax_office_name',
    companyName: 'company_name',
    taxRegistrationNumber: 'tax_registration_number',

    // General
    municipalityLabel: 'municipality',
    colonyLabel: 'colony',
    cashSelectorLabel: 'cash_selector',
    cashOptionsLabel: 'cash_options_label',
    pickupInConvenienceStore: 'pickup_in_convenience_store',
    convenienceStorePickupInstructions: 'convenience_store_pickup_instructions',
    convenienceStoreReceiptService: 'convenience_store_receipt_service',
    aboutConvenienceStoreReceiptService: 'about_convenience_store_receipt_service',
    specifiedShippingDateAndTime: 'specified_shipping_date_and_time',
    deliveryDateDesignation: 'delivery_date_designation',
    choosePreferredShippingDateTime: 'choose_preferred_shipping_date_time',
    shippingDate: 'shipping_date',
    shippingTime: 'shipping_time',
    feeTaxIncluded: 'fee_tax_included',
    rakutenPay: 'rakuten_pay',
    useRakutenPayToShop: 'use_rakuten_pay_to_shop',
    storeForClickAndCollect: 'store_for_click_and_collect',
    cashOnDeliveryFee: 'cash_on_delivery_fee',
    continueWithCod: 'continue_with_cod',
    continueWithRakuten: 'continue_with_rakuten',
    continueWithCreditCard: 'continue_with_credit_card',
    postalCodeSearchLink: 'postal_code_search_link',
    providePostalCodeLabel: 'provide_postal_code',
    cashOnDeliveryNotice: 'cash_on_delivery_notice',
    createAccountTitle: 'create_account_title',
    createAccountText: 'create_account_text',
    completeCheckout: 'complete_checkout'
};
