import { DrupalApi } from '@estee/elc-drupal-api';
import { IConfig, ITranslationsCollections } from '@estee/elc-service';

const { name, version } = __serviceInfo__;

export class TranslationsApiSdk extends DrupalApi {
    private translationsApiBasePath: string = 'elc_api/translations';
    private drupalDomain: string;

    constructor(config: IConfig) {
        super(config, name, version);
        this.drupalDomain = config.drupalDomain || '';
    }

    public fetchTranslations = async (payload: string[]) => {
        const url = this.getApiUrl(payload);
        const response = await this.fetch<ITranslationsCollections>({ url });

        return <ITranslationsCollections>response.data;
    };

    private static doCleanRequestPayload(payload: string[]) {
        return payload
            .sort()
            .map((p) => `${p}:*`)
            .join('&');
    }

    private getApiUrl(payload: string[]): string {
        const cleanPayload = TranslationsApiSdk.doCleanRequestPayload(payload);
        const host = this.drupalDomain || this.apiHost;
        const channel = this.channel;
        const tag = this.revTag;
        const args = [host, this.translationsApiBasePath, cleanPayload];
        const extras = [`buid:${this.businessUnitId}`];

        if (channel) {
            extras.push(`channel:${channel}`);
        }

        if (tag) {
            extras.push(`rev_tag:${tag}`);
        }

        args.push(extras.join('&'));

        return args.join('/');
    }
}
