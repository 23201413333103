export const Common = {
    artist: 'artist',
    logo: 'logo',
    logoAlt: 'logo_alt',
    homeUrl: 'home_url',
    copyright: 'copyright',
    contactNumber: 'contact_number',
    liveChat: 'live_chat',
    secureCheckoutLabel: 'secure_checkout',
    privacyPolicyLink: 'privacy_policy_link',
    noticeOfFinanicalIncentiveLink: 'notice_of_financial_incentive_link',
    loyaltyTermsLink: 'loyalty_terms_link',
    termsConditionsLink: 'terms_condition_link',
    termsOfSalesLink: 'terms_of_sales_link',
    termsOfServiceLink: 'terms_of_service_link',
    accessibilityLabel: 'accessibility',
    accessibilityLink: 'accessibility_link',
    liveChatLink: 'live_chat_link',
    privacyPolicy: 'privacy_policy',
    privacyPolicyLabel: 'privacy_policy',
    privacyPolicyDescription: 'privacy_policy_description',
    termsConditions: 'terms_conditions',
    customerServicePhoneNumber: 'customer_service_phone_number',
    deliveryHandlingUrl: 'shipping_handling_link',
    returnPolicyUrl: 'return_policy_link',
    contactEmailUrl: 'contact_us_link',
    contactNumberUrl: 'call_us_link',
    contactNumberLabel: 'customer_service_phone_number',
    contactNumberAriaLabel: 'customer_service_phone_number',
    charactersRemaining: 'characters_remaining',
    liveChatUrl: 'chat_link',
    bestSellersUrl: 'best_sellers_link',
    seeAllOffersUrl: 'see_all_offers_link',
    facebookShareUrl: 'facebook_share_url',
    twitterShareUrl: 'twitter_share_url',
    youtubeShareUrl: 'youtube_share_url',
    pinterestShareUrl: 'pinterest_share_url',
    vkShareUrl: 'vk_share_url',
    weChatShareUrl: 'wechat_share_url',
    weiboShareUrl: 'weibo_share_url',
    californiaConsumerPrivacyActLink: 'california_consumer_privacy_act_link',
    kvkkFooterLink: 'kvkk_footer_link',
    klarnaPaymentsOf: 'klarna_payments_of',
    klarnaAvailableForOrders: 'klarna_available_for_orders',
    counterPrivacyPolicyLink: 'counter_privacy_policy_link',
    onlineTermsAndConditionLink: 'online_terms_and_condition_link',
    cookieManagement: 'cookie_management',
    businessDealLink: 'business_deal_link',
    browserNotSupportedTitle: 'browser_not_supported_title',
    browserNotSupportedImage: 'browser_not_supported_image',
    browserNotSupportedText: 'browser_not_supported_text',
    browserNotSupportedHelpCentertext: 'browser_not_supported_help_center_text',
    browserNotSupportedContinueButtonText: 'browser_not_supported_continue_button_text',
    freeShippingAndReturn: 'free_shipping_and_return',
    reachBALabel: 'reach_ba',
    reachBAText: 'reach_ba_text',
    packaging: 'packaging',
    qrcodeTitle: 'qrcode_title',
    qrcodeText: 'qrcode_text',
    waitMessage: 'wait_message',
    google: 'google',
    ics: 'ics',
    onlyEnglishLanguageCharactersAreAccepted: 'only_english_language_characters_are_accepted',
    outlook: 'outlook',
    yahoo: 'yahoo',
    shIcpCodeLink: 'sh_icp_code_link',
    shBusinessLink: 'sh_business_link',
    personalMessage: 'personal_message',
    policeShieldLink: 'police_shield_link',
    joinLoyaltyRewardLink: 'join_loyalty_reward_link',
    joinLoyaltyRewardMessage: 'join_loyalty_reward_message',
    video: 'video',
    cpf: 'cpf',
    cpfRequiredLengthText: 'cpf_required_length_text',
    writeAPersonalMessage: 'write_a_personal_message'
};
