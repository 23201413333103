export const Stores = {
    application: 'application',
    apply: 'apply',
    askInStore: 'ask_in_store',
    atLabel: 'at',
    available: 'available',
    availableAt: 'available_at',
    availableforZipCode: 'available_for_zipcode',
    bookAppointment: 'book_appointment',
    callForAvailability: 'call_for_availability',
    callForOrder: 'call_for_order',
    chooseAStore: 'choose_a_store',
    collectInStore: 'collect_in_store',
    complimentaryEngraving: 'complimentary_engraving',
    contactStore: 'contact_store',
    curbsideIsAvailable: 'curbside_is_available',
    curbsidePickupAvailableText: 'curbside_pickup_text',
    deliveryOptions: 'delivery_options',
    deselectStore: 'deselect_store',
    doorTypeAirportDutyFreeStore: 'airport_duty_free_store',
    doorTypeDepartmentStore: 'department_store',
    doorTypeDutyFreeStore: 'duty_free_store',
    doorTypeFreeStandingStore: 'free_standing_store',
    doorTypePopupStore: 'popup_store',
    editDelivery: 'edit_delivery',
    enterAddressPlaceholder: 'enter_full_address',
    enterAddressToSeeAvailabilityArea: 'enter_address_to_see_availability_area',
    enterAnotherFullAddress: 'enter_another_full_address',
    enterFullAddressToConfirm: 'enter_full_address_to_confirm',
    filters: 'filters',
    findInStore: 'find_in_store',
    findStore: 'find_store',
    getDirections: 'get_directions',
    goToDirectoryLabel: 'go_to_directory',
    hours: 'hours',
    inStorePickupAvailableLabel: 'in_store_pickup_available',
    inStorePickupToggleSubheader: 'order_online_pickup_in_store',
    inStorePickupToggleTitle: 'in_store_pickup',
    inStoreServices: 'in_store_services',
    invalidZipcodeOrAddress: 'enter_zipcode_or_address',
    locations: 'stores_found',
    locationSearchInputPlaceholder: 'enter_full_address',
    moveTheMap: 'move_the_map',
    nearby: 'nearby',
    noFilterResultMessage: 'no_filter_result_message',
    notAvailable: 'not_available',
    onLabel: 'on',
    openNow: 'open_now',
    openNowToggleSubheader: 'stores_that_are_open_now',
    openTodayUntil: 'open_today_until',
    openUntil: 'open_until',
    otherNearbyLocations: 'other_neraby_locations',
    pickupFromStoreWithAllItems: 'pickup_from_store_with_all_items_available',
    pickUpInStore: 'pick_up_in_store',
    pickUpInStoreAddItemsToBagDescription: 'find_and_add_to_bag',
    pickUpInStoreAddItemsToBagTitle: 'add_items_to_bag',
    pickUpInStoreCheckoutDescription: 'click_buy_online_pick_up_in_store_and_select',
    pickUpInStoreCheckoutTitle: 'checkout',
    pickUpInStoreInformationDescription: 'currently_available_at',
    pickUpInStoreInformationTitle: 'buy_online_pick_up_in_store',
    pickUpInStoreNotificationDescription: 'pick_up_in_store_info_third_step_description',
    pickUpInStoreNotificationTitle: 'notification_and_pick_up',
    pickUpInStoreWantItToday: 'want_it_today',
    pickupNotAvailable: 'pickup_not_available',
    sddCheckoutDescription: 'same_day_delivery_info_second_step_description',
    sddCheckoutTitle: 'same_day_delivery_info_second_step_title',
    sddInformationDescription: 'available_for_certain_products_and_location',
    sddMightNotBeAvailable: 'sdd_might_not_be_available_for_zipcode_use_address',
    sddNotificationDescription: 'same_day_delivery_info_third_step_description',
    sddNotificationTitle: 'same_day_delivery_notification',
    searchPlaceholder: 'search_placeholder',
    searchThisArea: 'serach_this_area',
    seeAvailability: 'see_availability',
    selectedDeliveryMethod: 'selected_delivery_method',
    selectedStore: 'selected_store',
    selectFollowingToContinue: 'select_following_to_continue',
    serviceAvailableLabel: 'service_available',
    servicesAvailableToggleSubheader: 'in_store_appointments',
    servicesAvailableToggleTitle: 'services_available',
    shipItemsToAddress: 'ship_items_to_address',
    showMap: 'show_map',
    someItemsNotAvailablePickup: 'some_items_not_available_for_pickup',
    storeClosed: 'store_closed',
    storeClosingHour: 'store_closing_hour',
    storeDetails: 'store_details',
    storeDetailsMapAlt: 'static_map_alt_text',
    storeDirectory: 'store_directory',
    storeLabel: 'store',
    storeList: 'store_list',
    storeListTabTitle: 'store_list_tab_title',
    storeLocator: 'store_locator',
    storeMapTabTitle: 'store_map_tab_title',
    storeOpen: 'store_open',
    storeOpeningHour: 'store_opening_hour',
    storeOpeningHourFriday: 'opens_on_friday',
    storeOpeningHourMonday: 'opens_on_monday',
    storeOpeningHourSaturday: 'opens_on_saturday',
    storeOpeningHourSunday: 'opens_on_sunday',
    storeOpeningHourThursday: 'opens_on_thursday',
    storeOpeningHourToday: 'opens_today',
    storeOpeningHourTuesday: 'opens_on_tuesday',
    storeOpeningHourWednesday: 'opens_on_wednesday',
    storeOpeningIsClosed: 'store_is_closed',
    stores: 'stores',
    storesDirectoryCTA: 'stores_directory_cta',
    storesDirectorySubtitle: 'stores_directory_subtitle',
    storesDirectoryTitle: 'stores_directory_title',
    storeServicesSubtitle: 'in_store_services_subtitle',
    storeServicesTitle: 'in_store_services_title',
    storesFound: 'stores_found',
    storesHome: 'stores_home',
    storesLandingSubtitle: 'stores_landing_subtitle',
    storesLandingTitle: 'stores_landing_title',
    storesNext: 'stores_next',
    storesWithAvailableItems: 'only_stores_with_available_items',
    today: 'today',
    typeOfStore: 'type_of_store',
    viewAmountUpcomingEvents: 'view_amount_upcoming_events',
    weCouldNotFindAnyStoresInThisArea: 'we_could_not_find_any_stores_in_this_area',
    yourStore: 'your_store'
};
