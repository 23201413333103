/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action } from 'mobx';

export class ConfigStore {
    @observable public config: any;

    constructor(config: any) {
        this.updateConfig(config);
    }

    @action
    public updateConfig(config: any) {
        this.config = { ...config };
    }
}
