export const Vto = {
    bestLabel: 'best',
    bestPairLabel: 'best_pair',
    bestShadeMessage: 'your_best_match',
    casualPairLabel: 'casual_pair',
    categoryCleanse: 'product_benefit_type_cleanse',
    categoryConcentrate: 'product_benefit_type_concentrate',
    categoryCream: 'product_benefit_type_cream',
    categoryEyeCare: 'product_benefit_type_eye_care',
    categoryMoisturize: 'product_benefit_type_moisturize',
    categoryRepair: 'product_benefit_type_repair',
    categorySerum: 'product_benefit_type_serum',
    categoryTreat: 'product_benefit_type_treat',
    coolerLabel: 'cooler',
    coolerPairLabel: 'cooler_pair',
    darkerLabel: 'darker',
    glamPairLabel: 'glam_pair',
    lighterLabel: 'lighter',
    removeMyMatch: 'remove_my_match',
    saveMyMatch: 'save_my_match',
    seeAllShadesLabel: 'see_all_shades',
    seeBestShadesLabel: 'see_best_shades',
    seePairDetailsLabel: 'see_pair_details',
    seePairShadesLabel: 'see_pair_shades',
    skinCareResultTitle: 'vto_skin_care_result_title',
    skinCareStartOver: 'vto_skin_care_start_over',
    surveyCtaLabel: 'survey_cta_label',
    surveyDisclaimer: 'survey_disclaimer',
    surveyEndLabel: 'survey_end_label',
    surveyQuestionOneLabel: 'survey_question_one_label',
    surveyQuestionOneDescriptionLeft: 'survey_question_one_description_left',
    surveyQuestionOneDescriptionRight: 'survey_question_one_description_right',
    surveyQuestionTwoLabel: 'survey_question_two_label',
    surveyQuestionTwoPlaceholder: 'survey_question_two_placeholder',
    useSafariToAccessCamera: 'use_safari_to_access_the_camera',
    vtoFoundationButtonLabel: 'find_your_shade',
    vtoMakeupButtonLabel: 'try_it_on',
    vtoSkinCareButton: 'vto_skin_care_button',
    vtoSkinCareResultButton: 'vto_skin_care_result_button',
    warmerLabel: 'warmer',
    warmerPairLabel: 'warmer_pair',
    yourCoolerMatchLabel: 'your_cooler_match',
    yourDarkerMatchLabel: 'your_darker_match',
    yourLighterMatchLabel: 'your_lighter_match',
    yourPerfectMatchLabel: 'your_perfect_match',
    yourWarmerMatchLabel: 'your_warmer_match'
};
