export const Errors = {
    validationFailedError: 'validation_failed',
    fieldContainsBadCharError: 'field_contains_bad_characters',
    termsAndConditionsRequired: 'terms_conditions_required_error',
    cancellationErrorAlreadyCancelled: 'cancellation_already_expired',
    cancellationErrorCallStore: 'cancellation_call_store',
    cancellationErrorTokenExpired: 'cancellation_token_expired',
    cannotShipToAddressErrorMessage: 'cannot_ship_to_address',
    cityStateZipErrorMessage: 'city_state_zip_invalid',
    cityZipErrorMessage: 'city_zip_invalid',
    emailAddressInvalidMessage: 'email_address_invalid',
    emailAddressRequiredMessage: 'email_address_required',
    externalPaymentError: 'external_payment_error',
    afterpayExternalPaymentError: 'afterpay_external_payment_error',
    firstNameInvalidMessage: 'first_name_invalid',
    firstNameRequired: 'first_name_required',
    firstNameAlternateInvalidMessage: 'first_name_alternate_invalid',
    firstNameAlternateRequired: 'first_name_alternate_required',
    fullNameInvalidMessage: 'full_name_invalid',
    fullNameRequired: 'full_name_required',
    generic500Error: 'generic_500',
    genericError: 'generic_error',
    giftCardInformationErrorMessage: 'gift_card_information_error',
    giftCardZeroBalanceErrorMessage: 'gift_card_zero_balance',
    giftCardUnavailableErrorMessage: 'gift_card_unavailable',
    offerRemovedErrorMessage: 'offer_code_removed',
    invalidAddressErrorMessage: 'address_invalid',
    invalidBillingAddressSelectedErrorMessage: 'invalid_billing_address_selected',
    lastNameInvalidMessage: 'last_name_invalid',
    lastNameRequired: 'last_name_required',
    lastNameAlternateInvalidMessage: 'last_name_alternate_invalid',
    lastNameAlternateRequired: 'last_name_alternate_required',
    maxLengthInvalidMessage: 'max_length_invalid',
    minLengthInvalidMessage: 'min_length_invalid',
    patternInvalidMessage: 'pattern_invalid',
    paymentCurrencyNotMatchingConfigurationErrorMessage:
        'payment_currency_not_matching_configuration',
    upsAddressErrorMessage: 'ups_validation_error',
    giftCardErrorMessage: 'gift_card_error',
    paymentDisabledPaymentMethodErrorMessage: 'payment_disabled_payment_method',
    paymentExceededOrderSkuMaxLimitValueErrorMessage: 'payment_exceeded_order_sku_max_limit_value',
    paymentExceededQuantityValueErrorMessage: 'payment_exceeded_quantity_value',
    paymentExceededTotalValueErrorMessage: 'payment_exceeded_total_value',
    paymentLanguageNotMatchingConfigurationErrorMessage:
        'payment_language_not_matching_configuration',
    paymentMinimumTotalValueErrorMessage: 'payment_minimum_total_value',
    paymentNotInRangeTotalValueErrorMessage: 'payment_not_in_range_total_value',
    paymentProviderErrorMessage: 'payment_provider_error',
    paymentProvider3dsErrorMessage: 'payment_provider_3ds_error',
    paymentRestrictedZipCodeErrorMessage: 'payment_restricted_zip_code',
    phoneValidationErrorMessage: 'phone_validation_error',
    productApoRestrictionErrorMessage: 'restricted_apo_product',
    productsRemovedErrorMessage: 'product_removed_error',
    requiredFieldMessage: 'required_field',
    saveCCPreOrderItemsErrorMessage: 'save_cc_pre_order_items',
    saveCCReplenishmentServiceErrorMessage: 'save_cc_replenishment_service',
    shippingApoRestrictionErrorMessage: 'restricted_apo_address',
    shippingRestrictionErrorMessage: 'restricted_state',
    taxNumberErrorMessage: 'taxNumberErrorMessage',
    validityFieldMessage: 'field_invalid',
    weAreSorryAtThisTimeErrorMessage: 'we_are_sorry_at_this_time',
    clickAndCollectSearchErrorText: 'click_and_collect_search_error',
    geoSearchError: 'geo_search_error',
    salutationRequiredMessage: 'salutation_required',
    birthYearRequiredMessage: 'birth_year_required',
    birthMonthRequiredMessage: 'birth_month_required',
    birthDayRequiredMessage: 'birth_day_required',
    joinLoyaltyEnrolmentRequired: 'join_loyalty_enrolment_required',
    ageGroupRequiredMessage: 'age_group_required',
    customerLocationRequiredMessage: 'customer_location_required',
    countryCodeRequiredMessage: 'country_code_required',
    storesRequiredMessage: 'stores_required',
    payPalPaymentProviderErrorMessage: 'pay_pal_payment_provider_error',
    replenishmentRemovedErrorMessage: 'replenishment_removed_error',
    cartChangedErrorMessage: 'cart_changed_error',
    paypalCannotConnectErrorMessage: 'paypal_cannot_connect_error',
    itemNotAvailableForPickUpErrorMessage: 'item_not_available_for_pick_up_error',
    sameDayNotAvailableError: 'same_day_not_available_error_message',
    appointmentBookingFailed: 'appointment_booking_failed',
    appointmentCreationError: 'appointment_creation_error',
    appointmentUpdateError: 'appointment_update_error',
    appointmentCancellationError: 'appointment_cancellation_error',
    virtualAppointmentNoAvailability: 'virtual_appointment_no_availability',
    unableToCreateUserError: 'unable_to_create_user_error',
    noAppointmentAvailableError: 'no_appointment_available_error',
    maxAppointmentDurationCopy: 'max_appointment_duration_error_description',
    maxAppointmentDurationLabel: 'max_appointment_duration_error',
    defaultAPIError: 'default_api_error',
    invalidCardNumberError: 'invalid_card_number',
    invalidExpiryDateError: 'invalid_expiration_date',
    invalidEntry: 'invalid_entry',
    monthOutOfRangeError: 'month_out_of_range',
    yearOutOfRangeError: 'year_out_of_range',
    dateOutOfRangeError: 'date_out_of_range',
    invalidCvcError: 'invalid_cvv',
    emptyExpiryDateError: 'empty_expiry_date',
    emptyCardNumberError: 'empty_card_number',
    emptyCvvError: 'empty_cvv',
    taxOfficeNameInvalidMessage: 'tax_office_name_invalid',
    taxOfficeNameRequired: 'tax_office_name_required',
    companyNameInvalidMessage: 'company_name_invalid',
    companyNameRequired: 'company_name_required',
    companyTaxNumberInvalidMessage: 'company_tax_number_invalid',
    companyTaxNumberRequired: 'company_tax_number_required',
    sexRequiredMessage: 'sex_required',
    areaCodeRequiredMessage: 'area_code_required',
    homePhoneRequiredMessage: 'home_phone_required',
    homePhoneInvalidMessage: 'home_phone_invalid',
    mobileEmailRequired: 'mobile_email_required',
    mobileEmailInvalidMessage: 'mobile_email_invalid',
    primaryPhoneRequiredMessage: 'primary_phone_required',
    primaryPhoneInvalidMessage: 'primary_phone_invalid',
    secondaryPhoneRequiredMessage: 'secondary_phone_required',
    secondaryPhoneInvalidMessage: 'secondary_phone_invalid',
    municipalityRequiredMessage: 'municipality_required',
    municipalityValidMessage: 'municipality_invalid',
    colonyRequiredMessage: 'colony_required',
    colonyValidMessage: 'colony_invalid',
    emptyCardHolderNameError: 'empty_card_holder_name',
    shippingDateRequiredMessage: 'shipping_date_required',
    shippingDateInvalidMessage: 'shipping_date_invalid',
    shippingTimeRequiredMessage: 'shipping_time_required',
    shippingTimeInvalidMessage: 'shipping_time_invalid',
    shippingRestrictionHazmat: 'shipping_restriction_hazmat',
    shippingRestrictionOverseasTerritory: 'shipping_restriction_overseas_territory',
    packstationPostNumberInvalid: 'packstation_post_number_invalid',
    packstationNumberInvalid: 'packstation_number_invalid',
    packstationPostNumberRequired: 'packstation_post_number_required',
    packstationNumberRequired: 'packstation_number_required'
};
