import { Container } from 'hypo-container';
import { HttpTransport } from '@estee/elc-http-transport';
import { ServiceManager } from '@estee/elc-service';
import { IAppConfig } from '@estee/elc-universal-utils';
import { TranslationsApiSdk } from '~api/TranslationsApiSdk';
import { ConfigStore } from './ConfigStore';
import { TranslationsRepository } from '~repositories/TranslationsRepository';
import serviceNames, { TranslationsService } from './TranslationsService';
import { ServiceBusController } from './ServiceBusController';

export const diContainer = new Container();

const setConfig = (configJSON: IAppConfig) => {
    diContainer.config = configJSON;
};

diContainer
    .register(
        serviceNames.configStore,
        diContainer.factory((c) => new ConfigStore(c.config))
    )
    .register(serviceNames.httpTransport, (c) => {
        const configStore = c.get(serviceNames.configStore);
        const httpTransport = new HttpTransport();
        httpTransport.setBaseUrl(configStore.baseUrl);

        return httpTransport;
    })
    .register(serviceNames.translationsApiSdk, (c) => {
        const configStore = c.get(serviceNames.configStore);
        const httpTransport = c.get(serviceNames.httpTransport);
        const config = {
            businessUnitId: configStore.config.businessUnitId,
            httpTransport,
            drupalDomain: configStore.config.drupalDomain || ''
        };

        return new TranslationsApiSdk(config);
    })
    .register(serviceNames.translationsRepository, () => new TranslationsRepository())
    .register(
        serviceNames.serviceBusController,
        (c) => new ServiceBusController(c.get(serviceNames.translationsRepository))
    )
    .register(
        serviceNames.translationsService,
        (c) =>
            new TranslationsService((config: IAppConfig) => {
                setConfig(config);

                return c.get(serviceNames.serviceBusController);
            })
    );

export const serviceStarter = ServiceManager.startService;
