export const Loyalty = {
    receivedLoyaltyPointsMessage: 'received_loyalty_points',
    receiveLoyaltyPointsMessage: 'receive_loyalty_points',
    transactionSubTypeAnniversary: 'anniversary_bonus',
    transactionSubTypeAr: 'earned_from_auto_replenishment',
    transactionSubTypeBazaarVoice: 'earned_from_product_review',
    transactionSubTypeBv: 'earned_from_product_sub_review',
    transactionSubTypeBvReview: 'earned_from_writing_review',
    transactionSubTypeDiagnostic: 'earned_from_skin_care_diagnostic',
    transactionSubTypeDisenroll: 'clinique_smart_rewards_membership',
    transactionSubTypeEnrollment: 'earned_from_email_subscription',
    transactionSubTypeEnrollmentBonus: 'earned_from_joining_points',
    transactionSubTypeFacebook: 'earned_from_facebook_sharing',
    transactionSubTypeFoundationFinder: 'earned_from_foundation_finder',
    transactionSubTypeHistoricalPurchase: 'earned_from_historical_purchase',
    transactionSubTypeLiveChat: 'earned_from_live_chat',
    transactionSubTypeOnline: 'earned_from_online_purchase',
    transactionSubTypeOptinEmail: 'earned_from_optional_email_subscription',
    transactionSubTypeOptinSms: 'sms_subscription_bonus',
    transactionSubTypePinterest: 'earned_from_product_pinterest',
    transactionSubTypePintrest: 'earned_from_product_pinerest',
    transactionSubTypePpCarryover: 'pretty_smashcash_points',
    transactionSubTypeReferral: 'earned_from_refer_friend',
    transactionSubTypeReferralPurchase: 'earned_from_refer_friend_purchase',
    transactionSubTypeRetailPurchase: 'earned_from_instore_purchase',
    transactionSubTypeRetailer: 'earned_from_retailer_purchase',
    transactionSubTypeSkincare: 'earned_from_skincare',
    transactionSubTypeSocial1: 'social_share_bonus',
    transactionSubTypeStore: 'free_standing_store',
    transactionSubTypeTwitter: 'earned_from_twitter_sharing',
    transactionSubTypeYoutime: 'youtime_in_store',
    transactionTypeAdjustment: 'adjustment',
    transactionTypeAnniversary: 'anniversary_points_expired',
    transactionTypeCancellation: 'cancellation',
    transactionTypeCardIssued: 'egift_card_issued',
    transactionTypeCustomerService: 'customer_service',
    transactionTypeDisenroll: 'cancelled',
    transactionTypeExchange: 'exchange',
    transactionTypeMultiple: 'multiple_adjustment',
    transactionTypePurchase: 'online_purchase',
    transactionTypeReturn: 'return',
    transactionTypeReward: 'reward_points',
    transactionTypeUsed: 'points_used',
    transactionTypeEnrollment: 'enrollment',
    transactionTypeUpgrade: 'reached',
    transactionTypePointsToRedeem: 'points_to_redeem',
    transactionEventPendingOrder: 'pending_order'
};
