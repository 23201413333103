import { BaseService } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class TranslationsService extends BaseService {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
    }
}

export default {
    configStore: 'CONFIG_STORE',
    httpTransport: 'HTTP_TRANSPORT',
    translationsApiSdk: 'API_SDK',
    translationsRepository: 'TRANSLATIONS_REPOSITORY',
    translationsService: 'TRANSLATIONS_SERVICE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER'
};
