export const DeliveryTout = {
    sdSubtitle: 'sd_subtitle',
    sdCopy: 'sd_copy',
    sdIcon: 'sd_icon',
    sdShippingTimes: 'sd_shipping_times',
    nddSubtitle: 'ndd_subtitle',
    nddCopy: 'ndd_copy',
    nddIcon: 'ndd_icon',
    nddShippingTimes: 'ndd_shipping_times',
    sddSubtitle: 'sdd_subtitle',
    sddCopy: 'sdd_copy',
    sddIcon: 'sdd_icon',
    sddShippingTimes: 'sdd_shipping_times',
    nmddSubtitle: 'nmdd_subtitle',
    nmddCopy: 'nmdd_copy',
    nmddIcon: 'nmdd_icon',
    nmddShippingTimes: 'nmdd_shipping_times',
    samdSubtitle: 'samd_subtitle',
    samdCopy: 'samd_copy',
    samdIcon: 'samd_icon',
    samdShippingTimes: 'samd_shipping_times',
    ccdSubtitle: 'ccd_subtitle',
    ccdCopy: 'ccd_copy',
    ccdIcon: 'ccd_icon',
    ccdShippingTimes: 'ccd_shipping_times',
    shippingAndHandlingTitle: 'shipping_and_handling_title',
    shippingAndHandlingSubtitle: 'shipping_and_handling_subtitle',
    shippingInformationTitle: 'shipping_information_title',
    shippingPopUpCopy: 'shipping_pop_up_copy',
    shipMethodPopUpTitle: 'ship_method_popup_title',
    costPopUpTitle: 'cost_popup_title',
    shippingTimeFrameTitle: 'shipping_time_frame_title',
    otherLocationsTitle: 'other_locations_title',
    otherLocationsCopy: 'other_locations_copy'
};
