import { root } from '@estee/elc-universal-utils';
import { ELCLogger, TriggerType } from '@estee/elc-logging';
import { ITranslationsCollection, ITranslationsCollections } from '@estee/elc-service';
import { TranslationsApiSdk } from '~api/TranslationsApiSdk';
import { ConfigStore } from '~setup/ConfigStore';
import {
    generateLegacyFormat,
    getTranslationsMap,
    translationsCollections
} from '../translation-mappings';
import { diContainer } from '~setup/diContainer';
import serviceNames from '~setup/TranslationsService';

const { name, version } = __serviceInfo__;

export interface ITranslationRepositoryConfig {
    configStore: ConfigStore;
}

export class TranslationsRepository {
    private translations: ITranslationsCollections = {};
    private translationsApiSdk: TranslationsApiSdk;
    private readonly translationMapping: ITranslationsCollection;
    private config: ITranslationRepositoryConfig;
    private logger: ELCLogger = new ELCLogger({
        serviceName: name,
        environment: root.env,
        buid: root.buid,
        serviceVersion: version
    });
    private windowTranslations: Promise<ITranslationsCollections>;

    constructor() {
        this.translationsApiSdk = diContainer.get(serviceNames.translationsApiSdk);
        this.translationMapping = getTranslationsMap();
        this.config = {
            configStore: diContainer.get(serviceNames.configStore)
        };
        this.windowTranslations = this.processTranslationsFromWindow().catch(error => {
            this.logger.error({
                triggerType: TriggerType.api,
                message: 'Error getting translations from window',
                payload: { error }
            });

            return this.translations;
        });
    }

    private processTranslationsFromWindow = async () => {
        // Load collections from window.site.translations, if available
        let data = this.loadFromWindow;

        if (Object.keys(data).length === 0) {
            // Load missing collections from the API and keep them in memory
            data = await this.loadFromApi(Object.keys(translationsCollections));
            root.site = { translations: data };
        }

        this.setTranslations(data);

        return data;
    };

    /**
     * Get translations for the query provider service
     *
     * @param fieldsRequested Fields to retrieve
     *
     * @return Regular object of key/value pairs with translations
     */
    public getTranslations = async (fieldsRequested: string[]): Promise<{}> => {
        fieldsRequested.sort();

        if (fieldsRequested.length === 0) {
            const error = new Error('Requested translations fields are empty');
            this.logger.error({
                message: error.message,
                triggerType: TriggerType.translation,
                payload: {
                    error
                }
            });

            return {};
        }

        await this.hydrateTranslations(fieldsRequested);

        return fieldsRequested.reduce((accumulator: ITranslationsCollection, field: string) => {
            Object.defineProperty(accumulator, field, {
                value: this.getFieldValue(field),
                writable: false,
                enumerable: true
            });

            return accumulator;
        }, {});
    };

    /**
     * Given a field lookup key (ex. 'product.add_to_bag'), get the hydrated
     * value from the persistent data storage
     *
     * @param field Field lookup key
     */
    private getFieldValue = (field: string): string => {
        const { translations } = this;
        const fieldMap = field in this.translationMapping ? this.translationMapping[field] : false;

        if (!fieldMap) {
            const error = new Error(`Translation field ${field} not found`);
            this.logger.warning({
                message: error.message,
                triggerType: TriggerType.translation,
                payload: {
                    error
                }
            });

            return '';
        }

        const [collectionName, legacyFieldName] = fieldMap.split('.');
        if (collectionName in translations && legacyFieldName in translations[collectionName]) {
            return translations[collectionName][legacyFieldName];
        }

        return '';
    };

    /**
     * Central method of hydrating translations from multiple sources
     *
     * @param fieldsRequested Field names to retrieve
     */
    private hydrateTranslations = async (fieldsRequested: string[]): Promise<void> => {
        const payload = generateLegacyFormat(fieldsRequested, this.translationMapping);

        // Use the payload to set the initial translations dataset
        this.setTranslations(payload);

        // In case fetch hasn't finished yet
        const windowTranslations = await this.windowTranslations;
        this.setTranslations(windowTranslations);

        // Do final cleanup on the translations datasets
        const data = this.cleanTranslations();
        this.setTranslations(data);
    };

    /**
     * Hydrate the translations given a new legacy format dataset
     */
    private setTranslations(data: ITranslationsCollections): void {
        Object.entries(data).forEach(
            ([collectionName, collectionValue]) => {
                Object.defineProperty(this.translations, collectionName, {
                    value: {
                        ...this.translations[collectionName],
                        ...collectionValue
                    },
                    writable: true,
                    enumerable: true
                });
            }
        );
    }

    private addDefaultVals(collectionName: string, collection: ITranslationsCollection) {
        const { configStore } = this.config;
        const showPlaceholder =
            configStore.config && configStore.config.showTranslationsPlaceholder !== false;

        // Add ":: <field> ::" values to any missing items based on AppEcomm config
        return Object.entries(collection).reduce(
            (accumulator, [collectionKey, collectionValue]
        ) => {
            if (!collectionValue) {
                Object.defineProperty(accumulator, collectionKey, {
                    value: showPlaceholder ? `::${collectionName}.${collectionKey}::` : '',
                    writable: true,
                    enumerable: true
                });
            }

            return accumulator;
        }, {});
    }

    /**
     * Helper function to clean up empty translation strings
     * This replaces any blank values with ":: <field name> ::"
     */
    private cleanTranslations(): ITranslationsCollections {
        return Object.entries(this.translations).reduce(
            (accumulator: ITranslationsCollections, [collectionName, collectionValue]) => {
                const newCollection = <ITranslationsCollection>(
                    this.addDefaultVals(collectionName, collectionValue)
                );

                accumulator[collectionName] = {
                    ...collectionValue,
                    ...newCollection
                };

                return accumulator;
            },
            {}
        );
    }

    /**
     * Load translations from site.translations.<key>
     * Note: no need to pass the array of collections here since
     *       we just hydrate with all data.
     */
    get loadFromWindow(): ITranslationsCollections {
        return (root && root.site && root.site.translations) || {};
    }

    /**
     * Load translations using the API when all other options have been exhausted
     *
     * @param collections Array of collection names
     */
    private loadFromApi = (collections: string[]) => {
        return this.translationsApiSdk.fetchTranslations(collections);
    };
}
