export const Orders = {
    trackMyOrder: 'track_my_order',
    trackMyOrderGuidance: 'track_my_order_guidance',
    pleaseInputYourOrderNumber: 'please_input_your_order_number',
    trackOrder: 'track_order',

    orderProcessing: 'order_processing',
    yourOrderIsProcessing: 'your_order_is_processing',
    orderStatusProcessingInstruction: 'order_status_processing_instruction',
    orderStatusProcessingMessage: 'order_status_processing_message',
    yourOrderIsNowOnBackorder: 'your_order_is_now_on_backorder',

    atWarehouse: 'at_warehouse',
    yourOrderIsAtWarehouse: 'your_order_is_at_warehouse',
    orderStatusAtWarehouseInstruction: 'order_status_at_warehouse_instruction',
    orderStatusAtWarehouseMessage: 'order_status_at_warehouse_message',

    shipped: 'shipped',
    yourOrderIsShipped: 'your_order_is_shipped',
    orderStatusShippedInstruction: 'order_status_shipped_instruction',
    orderStatusShippedMessage: 'order_status_shipped_message',
    orderStatusPartiallyShippedMessage: 'your_order_is_partially_shipped',
    orderStatusOutForDeliveryShippedMessage: 'your_order_is_out_for_delivery',

    delivered: 'delivered',
    yourOrderIsDelivered: 'your_order_is_delivered',
    orderStatusDeliveredInstruction: 'order_status_delivered_instruction',
    orderStatusDeliveredMessage: 'order_status_delivered_message',

    returned: 'returned',
    yourOrderIsReturned: 'your_order_is_returned',
    yourPartialOrderIsReturned: 'your_partial_order_is_returned',
    orderStatusReturnedInstruction: 'order_status_returned_instruction',
    orderStatusReturnedMessage: 'order_has_been_returned_by_customer',
    orderStatusPartialReturnedMessage: 'order_has_been_partial_returned_by_customer',

    returnInProgress: 'return_in_progress',
    yourOrderIsReturnInProgress: 'your_order_is_return_in_progress',
    yourPartialOrderIsReturnInProgress: 'your_partial_order_is_return_in_progress',
    orderStatusReturnInProgressInstruction: 'order_status_return_in_progress_instruction',
    orderStatusReturnInProgressMessage: 'order_status_return_in_progress_message',
    orderStatusPartialReturnInProgressMessage: 'order_status_partial_return_in_progress_message',

    returnInitiated: 'return_initiated',
    yourOrderIsReturnInitiated: 'your_order_is_return_initiated',
    orderStatusReturnInitiatedInstruction: 'order_status_return_initiated_instruction',
    orderHasBeenReturnInitiated: 'order_has_been_return_initiated',

    returnShipped: 'return_shipped',
    yourOrderIsReturnToShipped: 'your_order_is_return_to_shipped',
    orderStatusReturnToShippedInstruction: 'order_status_return_to_shipped_instruction',
    orderHasBeenReturnToShipped: 'order_has_been_return_to_shipped',

    returnReceived: 'return_received',
    yourOrderIsReturnReceived: 'your_order_is_return_received',
    orderStatusReturnReceivedInstruction: 'order_status_return_received_instruction',
    orderHasBeenReturnReceived: 'order_has_been_return_received',

    unableToDeliver: 'unable_to_deliver',
    yourOrderIsUnableToDeliver: 'your_order_is_unable_to_deliver',
    orderStatusUnableToDeliverInstruction: 'order_status_unable_to_deliver_instruction',
    orderHasBeenUnableToDeliver: 'order_has_been_unable_to_deliver',

    cancelled: 'cancelled',
    yourOrderIsCancelled: 'your_order_is_cancelled',
    orderStatusCancelledInstruction: 'order_status_cancelled_instruction',
    orderStatusCancelledMessage: 'order_status_cancelled_message',
    orderStatusRemorseCancelledMessage: 'your_order_is_cancelled_by_remorse',
    orderStatusCustomerServiceCancelledMessage: 'your_order_is_cancelled_by_customer_service',

    delayed: 'delayed',
    yourOrderIsDelayed: 'your_order_is_delayed',
    orderStatusDelayedInstruction: 'order_status_delayed_instruction',
    orderStatusDelayedMessage: 'order_has_been_delayed_to_shipped',

    orderNumberInvalidErrorMessage: 'order_number_invalid',
    noOrderFoundErrorMessage: 'no_order_found',
    orderTrackingGenericErrorMessage: 'order_tracking_generic_error',

    deliveryMethodLabel: 'delivery_method',
    orderStatusExplainedLabel: 'order_status_explained',
    shippingInformationLabel: 'shipping_information',
    carrierNameLabel: 'carrier_name',
    shippedDateLabel: 'shipped_date',
    trackingNumberLabel: 'tracking_number',
    seeDeliveryDetails: 'see_delivery_details',

    orderNumberTooltipText: 'order_number_information',
    orderDetails: 'order_details',
    yourOrder: 'your_order',
    orderStatus: 'order_status',
    estimatedDelivery: 'estimated_delivery',
    deliveredOn: 'delivered_on',
    awaitingCarrierPickUp : 'awaiting_carrier_pick_up',
    needHelp: 'need_help',
    yourOrderArriveInSeparateBoxes: 'your_order_arrive_in_separate_boxes',
    orderNumberLabelForPartialShipment: 'order_number_label_for_partial_shipment'

};
