export const Product = {
    ascending: 'product_name_ascending',
    descending: 'product_name_descending',
    topRated: 'sort_top_rated',
    priceLowToHigh: 'sort_price_low_to_high',
    priceHighToLow: 'sort_price_high_to_low',
    orderByName: 'sort_product_name',
    orderByNameDropDownTitle: 'sort_by',
    outOfStockButtonLabel: 'temp_out_of_stock',
    soldOutButtonLabel: 'sold_out',
    comingSoonButtonLabel: 'coming_soon',
    shopNowButtonLabel: 'shop_now',
    ctaLinkLabel: 'product_details',
    productBadgeImageAlt: 'product_badge_image_alt',
    skuBadgeImageAlt: 'sku_badge_image_alt',
    productSizePickerLabel: 'choose_size',
    productDetailsAccordionLabel: 'details',
    productHowToUseAccordionLabel: 'how_to_use',
    productHowDoesItWorkAccordionLabel: 'how_does_it_work',
    productIngredientsAccordionLabel: 'ingredients',
    productKeyIngredientAccordionLabel: 'key_ingredient',
    productWhatItIsAccordionLabel: 'what_it_is',
    productWhatItDoesAccordionLabel: 'what_it_does',
    ingredientsDisclaimerMessage: 'ingredients_disclaimer',
    readReviewsLabel: 'read_reviews',
    sizeLabel: 'size',
    skinType: 'filter_skin_type',
    skinTone: 'filter_skin_tone',
    defaultPromoMessage: 'default_promo_message',
    sortByLabel: 'sort_by',
    texture: 'filter_texture',
    productSizeLabel: 'product_size',
    writeReviewLabel: 'write_review',
    quickViewButtonLabel: 'quick_view',
    overviewLabel: 'overview',
    benefitsLabel: 'benefits',
    whoItsForLabel: 'who_its_for',
    whatItIsLabel: 'what_it_is',
    whatItDoesLabel: 'what_it_does',
    greatToKnowLabel: 'great_to_know',
    keyIngredientsLabel: 'key_ingredients',
    finishLabel: 'finish',
    coverageLabel: 'coverage',
    skinTypeLabel: 'skin_type',
    horizontalFiltersTitle: 'horizontal_filters_title',
    productsItemLabel: 'products_item',
    seeProductsLabel: 'see_products',
    clearFiltersLabel: 'clear_filters',
    hideFiltersLabel: 'hide_filters',
    filtersLabel: 'filters',
    autoReplenishLabel: 'auto_replenish',
    selectFrequencyLabel: 'select_frequency',
    subscribeCopyLabel: 'subscribe_copy',
    subscribeLabel: 'subscribe',
    selectYourShadeLabel: 'select_your_shade',
    selectShadeLabel: 'select_shade',
    shadeLabel: 'shade',
    overOneShadeLabel: 'over_one_shade',
    overTwoShadesLabel: 'over_two_shades',
    overThreeShadesLabel: 'over_three_shades',
    overFourShadesLabel: 'over_four_shades',
    colorGroupLabel: 'color_group',
    undertoneLabel: 'undertone',
    tempOutOfStockLabel: 'temp_out_of_stock_title',
    itemIsTempOutOfStock: 'item_is_temp_out_of_stock',
    itemIsTempOutOfStockNotifyMe: 'item_is_temp_out_of_stock_notify_me',
    itemIsComingSoon: 'item_is_coming_soon',
    itemIsComingSoonNotifyMe: 'item_is_coming_soon_notify_me',
    itemIsSoldOut: 'item_is_sold_out',
    firstToWriteReviewLabel: 'first_to_write_review',
    selectASizeLabel: 'select_a_size',
    giftsFilterTitle: 'gifts_filter_title',
    applyFiltersLabel: 'apply_filters',
    filterByCategoryLabel: 'filter_by_category',
    filterByPriceLabel: 'filter_by_price',
    sheerCoverageLabel: 'sheer_coverage',
    mediumCoverageLabel: 'medium_coverage',
    fullCoverageLabel: 'full_coverage',
    matteFinishLabel: 'matte_finish',
    satinFinishLabel: 'satin_finish',
    shineFinishLabel: 'shine_finish',
    naturalFinishLabel: 'natural_finish',
    shimmerFinishLabel: 'shimmer_finish',
    categoriesAllLabel: 'categories_all',
    categoryCremeLabel: 'category_creme',
    categoryChromeLabel: 'category_chrome',
    categoryDeepLabel: 'category_deep',
    categoryLightLabel: 'category_light',
    categoryLimitedEditionLabel: 'category_limited_edition',
    categoryMediumLabel: 'category_medium',
    categoryMatteLabel: 'category_matte',
    categoryMatteFinishLabel: 'category_matte_finish',
    categoryMetallicLabel: 'category_metallic',
    categoryFoilFinishLabel: 'category_foil_finish',
    categoryShineLabel: 'category_shine',
    categorySparkleLabel: 'category_sparkle',
    categoryShimmerLabel: 'category_shimmer',
    categoryRedLabel: 'category_red',
    categoryPurpleLabel: 'category_purple',
    categoryOrangeLabel: 'category_orange',
    categoryNewLabel: 'category_new',
    categoryNudeLabel: 'category_nude',
    productQuantityLabel: 'product_quantity',
    resultsLabel: 'results',
    shadesLabel: 'shades',
    learnMoreLabel: 'learn_more',
    sensitiveSkinTypeLabel: 'sensitive_skin_type',
    veryDrySkinTypeLabel: 'very_dry_skin_type',
    drySkinTypeLabel: 'dry_skin_type',
    antiAgingSkinTypeLabel: 'anti_aging_skin_type',
    combinationSkinTypeLabel: 'combination_skin_type',
    oilySkinTypeLabel: 'oily_skin_type',
    normalSkinTypeLabel: 'normal_skin_type',
    numberOfShadesLabel: 'number_of_shades',
    violetColorFamilyLabel: 'violet_color_family',
    pinkColorFamilyLabel: 'pink_color_family',
    redColorFamilyLabel: 'red_color_family',
    nudeColorFamilyLabel: 'nude_color_family',
    fairColorFamilyLabel: 'fair_color_family',
    veryFairColorFamilyLabel: 'very_fair_color_family',
    mediumColorFamilyLabel: 'medium_color_family',
    deepColorFamilyLabel: 'deep_color_family',
    berryColorFamily: 'berry_color_family',
    brownColorFamily: 'brown_color_family',
    beigeColorFamily: 'beige_color_family',
    darkColorFamily: 'dark_color_family',
    greenColorFamily: 'green_color_family',
    greyColorFamily: 'grey_color_family',
    lightColorFamily: 'light_color_family',
    mediumFairColorFamily: 'medium_fair_color_family',
    moderateColorFamily: 'moderate_color_family',
    orangeColorFamily: 'orange_color_family',
    blueColorFamily: 'blue_color_family',
    purpleColorFamily: 'purple_color_family',
    otherColorFamily: 'other_color_family',
    yellowColorFamily: 'yellow_color_family',
    coolUndertoneLabel: 'cool_undertone',
    neutralUndertoneLabel: 'neutral_undertone',
    warmUndertoneLabel: 'warm_undertone',
    goldenUndertoneLabel: 'golden_undertone',
    installmentsLabel: 'installments',
    installmentsUnavailableLabel: 'installments_unavailable',
    installmentsModalLabel: 'installments_modal',
    autoReplenishMoreInformation: 'auto_replenish_more_information',
    crossSellsTitle: 'cross_sells_title',
    increaseProductQuantityLabel: 'increase_product_quantity',
    decreaseProductQuantityLabel: 'decrease_product_quantity',
    removeIconAriaLabel: 'remove_icon_aria_label',
    collapseIconAriaLabel: 'collapse_icon_aria_label',
    expandIconAriaLabel: 'expand_icon_aria_label',
    replenishmentServiceLabel: 'replenishment_service',
    aboveReplenishmentServiceLabel: 'above_replenishment_service',
    temporarilyOutOfStockLabel: 'temporarily_out_of_stock',
    engravingModalLabel: 'engraving_modal',
    engravingLink: 'engrave_link',
    olapicWidgetTitle: 'olapic_widget_title',
    olapicWidgetSubtitle: 'olapic_widget_subtitle',
    filterByLabel: 'filter_by_label',
    aFewLeftLabel: 'a_few_left',
    aFewLeftThresholdRange: 'a_few_left_threshold_range',
    onlyCountLeftLabel: 'only_count_left',
    onlyCountLeftThresholdLimit: 'only_count_left_threshold_limit',
    reviewsTabLabel: 'reviews',
    askAnswerTabsLabel: 'ask_answer',
    perfectFor: 'perfect_for',
    reviewsNumberLabel: 'reviews_number',
    shipsBy: 'ships_by',
    preorderNotAvailableMessage: 'preorder_not_available_message',
    availableOn: 'available_on',
    notifyWhenAvailable: 'notify_when_available',
    preOrder: 'pre_order',
    taxIncluded: 'tax_included',
    smartGiftButtonLabel: 'smartgift',
    smartGiftInfoTitle: 'smartgift_info_title',
    discountPercentLabel: 'discount_percent',
    loyaltyMessage: 'loyalty_message',
    loyaltyRangeMessage: 'loyalty_range_message',
    loyaltyLearnMoreMessage: 'loyalty_learn_more_message',
    loyaltyLearnMoreLink: 'loyalty_learn_more_link',
    discountedPricePerUnitMessage: 'discounted_price_per_unit_message',
    discountedPriceRangePercentMessage: 'discounted_price_range_percent_message',
    onSaleTodayLabel: 'on_sale_today',
    talkToAConsultantLabel: 'talk_to_a_consultant',
    allSizesLabel: 'all_sizes',
    benefitLabel: 'benefit',
    idealForLabel: 'ideal_for',
    luxuryCareForLabel: 'luxury_care_for',
    feelingLabel: 'feeling',
    fraganceTypeLabel: 'fragance_type',
    notesLabel: 'notes',
    applicatorStyleLabel: 'applicator_style',
    brushLabel: 'brush',
    colorImpactLabel: 'color_impact',
    formLabel: 'form',
    dimensionsLabel: 'dimensions',
    refillableLabel: 'refillable',
    formulaFactsLabel: 'formula_facts',
    giftSetLabel: 'gift_set',
    selectAnItemLabel: 'select_an_item',
    bestUsedByLabel: 'best_used_by'
};
