/* eslint-disable no-restricted-syntax */
import { ITranslationsCollections, ITranslationsCollection } from '@estee/elc-service';
import { Account } from './Account';
import { AccountErrors } from './AccountErrors';
import { Cart } from './Cart';
import { CartErrors } from './CartErrors';
import { Checkout } from './Checkout';
import { Communications } from './Communications';
import { Errors } from './Errors';
import { General } from './General';
import { Loyalty } from './Loyalty';
import { Product } from './Product';
import { ProductAttributes } from './ProductAttributes';
import { User } from './User';
import { Common } from './Common';
import { Offers } from './Offers';
import { Stores } from './Stores';
import { Search } from './Search';
import { ApptBooking } from './ApptBooking';
import { Compliance } from './Compliance';
import { Vto } from './Vto';
import { DeliveryTout } from './DeliveryTout';
import { Orders } from './Orders';
import { Gifting } from './Gifting';

export const translationsCollections: ITranslationsCollections = {
    elc_account: Account,
    elc_account_errors: AccountErrors,
    elc_appt_booking: ApptBooking,
    elc_cart: Cart,
    elc_cart_errors: CartErrors,
    elc_checkout: Checkout,
    elc_common: Common,
    elc_communications: Communications,
    elc_compliance: Compliance,
    elc_errors: Errors,
    elc_general: General,
    elc_loyalty: Loyalty,
    elc_offers: Offers,
    elc_vto: Vto,
    elc_product: Product,
    elc_product_attributes: ProductAttributes,
    elc_stores: Stores,
    elc_user: User,
    elc_search: Search,
    elc_delivery_tout: DeliveryTout,
    elc_orders: Orders,
    elc_gifting: Gifting
};

/**
 * Convert the exported translations mapping files (ex: elc_product.ts) into a single
 * large object of key/value pairs.
 *
 * Example:
 *   {
 *       addToWishlist: "elc_product.add_to_wishlist",
 *       taxNumberErrorMessage: "elc_errors.taxNumberErrorMessage"
 *   }
 */
export const getTranslationsMap = () => {
    return Object.keys(translationsCollections).reduce<Record<string, string>>(
        (accum, collectionName) => {
            Object.keys(translationsCollections[collectionName]).reduce<Record<string, string>>(
                (data, key) => {
                    const legacyKey = translationsCollections[collectionName][key];

                    if (legacyKey.indexOf('.') !== -1) {
                        throw new Error('Invalid key name. Keys must not contain periods.');
                    }

                    data[key] = `${collectionName}.${legacyKey}`;

                    return data;
                },
                accum
            );

            return accum;
        },
        {}
    );
};

/**
 * Given an array of requested fields, this generates the legacy format for
 * which an API request can be generated.
 *
 * Input:
 *   ['addToWishlist', 'taxNumberErrorMessage']
 *
 * Output:
 *   {
 *       elc_product: {
 *           add_to_wishlist: ''
 *       },
 *       elc_errors: {
 *           taxNumberErrorMessage: ''
 *       }
 *   }
 *
 * @param fieldsRequested Array of field names requested from the component
 * @param fullDataMap Output from getTranslationsMap, persisted from the repository
 */
export const generateLegacyFormat = (
    fieldsRequested: string[],
    fullDataMap: ITranslationsCollection
) => {
    return fieldsRequested.reduce((accumulator: ITranslationsCollections, field: string) => {
        if (!fullDataMap[field]) {
            return accumulator;
        }

        const key = fullDataMap[field] as string;

        const [collectionName, collectionKey] = key.split('.');

        if (!(collectionName in accumulator)) {
            accumulator[collectionName] = {};
        }
        accumulator[collectionName][collectionKey] = '';

        return accumulator;
    }, {});
};
