export const Search = {
    searchButtonLabel: 'search',
    searchBoxLabel: 'search',
    searchPlaceholderLabel: 'placeholder',
    searchClearLabel: 'search_clear',
    bestSellersProductsTitle: 'best_sellers',
    moreResultsFoundMessage: 'more_results_found',
    noResultsMessage: 'no_results',
    resultsFoundMessage: 'results_found',
    resultsFoundMessageOverlay: 'results_found_overlay',
    viewAllResultsLabel: 'view_all_results',
    inCategoryLabel: 'in_category',
    verticalFiltersTitle: 'vertical_filters_title',
    filteredBy: 'filtered_by',
    clearAll: 'search_clear_filters',
    suggestedTopFilters: 'suggested_top_filters',
    searchAdjustmentMessage: 'search_adjustment_message',
    voiceMicrophoneAccess: 'voice_microphone_access',
    voiceSearchFailureTitle: 'voice_search_failure_title',
    voiceSearchFailureParagraph: 'voice_search_failure_paragraph',
    voiceListenTitle: 'voice_listen_title',
    voiceListenParagraph: 'voice_listen_paragraph',
    voiceListenTermsExample: 'voice_listen_terms_example',
    speakNow: 'speak_now',
    voiceSearchingTitle: 'voice_searching_title',
    sortTopRated: 'sort_top_rated',
    sortPriceLowHigh: 'sort_price_low_high',
    sortPriceHighLow: 'sort_price_high_low',
    mobileFiltersButton: 'mobile_filters_button',
    horizontalFiltersTitle: 'horizontal_filters_title',
    trendingTermsTitle: 'trending_terms_title',
    trendingResultsTitle: 'trending_results_title',
    productsTitle: 'products_title',
    storiesTitle: 'stories_title',
    readStory: 'read_story',
    voiceSearchIconLabel: 'voice_search_icon_label'
};
